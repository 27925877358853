import { Container, Row, Col } from "react-bootstrap/";
import { Typewriter } from "react-simple-typewriter";
import "./LandingPage.css";
import Atropos from "atropos/react";
import "atropos/atropos.css";

function LandingPage() {
  return (
    <Container className="landingPageContainer">
      <Row>
        <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Atropos
            component="image"
            activeOffset={10}
            shadow={false}
            highlight={false}
          >
            <img
              className="landingImg"
              src={require("../images/Divyansh_Updated.jpeg")}
              alt="Div's Landing"
            />
          </Atropos>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="my-auto">
          <div className="introText">
            <div className="typewriteText">
              <Typewriter
                words={["", "Hey, I'm Divyansh Goyal!"]}
                loop={1}
                cursor={false}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={70}
              />
            </div>
            <div className="subTitle">
              <p>
                I am an aspiring software developer who is extremely passionate
                to learn and innovate.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default LandingPage;
