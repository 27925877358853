import React from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap/";
import "./Skills.css";
import { useInView } from "react-intersection-observer";

function Skills() {
  const { ref: skillsRef, inView: skillsVisible } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });
  return (
    <Container fluid className="skillsContainer" id="skills" ref={skillsRef}>
      <div
        className={`${"skillsOrig"} ${skillsVisible ? "skillsAnim" : "skillsOrig"
          }`}
      >
        <h1 className="skillsTitle">Skills</h1>
        <hr className="titleHr" />
        <br />
        <Tab.Container id="skillsTab" defaultActiveKey="languages">
          <Row>
            <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
              <Nav className="navTabCol flex-column">
                <Nav.Item className="tabNav">
                  <Nav.Link className="tabPane" eventKey="languages">
                    Programming Languages
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="tabNav">
                  <Nav.Link className="tabPane" eventKey="web">
                    Web Technologies
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="tabNav">
                  <Nav.Link className="tabPane" eventKey="tools">
                    Tools/Libraries
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="tabNav">
                  <Nav.Link className="tabPane" eventKey="business">
                    Business Abilities
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col xs={12} sm={12} md={12} lg={9} xl={9} xxl={9}>
              <Tab.Content>
                <Tab.Pane eventKey="languages">
                  <Row>
                    <Col
                      className="Languages1"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={6}
                    >
                      <li>Python</li>
                      <li>C/C++</li>
                    </Col>
                    <Col
                      className="Languages2"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={6}
                    >
                      <li>Java</li>
                      <li>Batch &amp; Shell Scripting</li>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="web">
                  <Row className="tabRow">
                    <Col
                      className="web1"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={6}
                    >
                      <li>HTML &amp; CSS (Coursera Certified)</li>
                      <li>React.js</li>
                      <li>Next.js</li>
                      <li>JavaScript</li>
                    </Col>
                    <Col
                      className="web2"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={6}
                    >
                      <li>Django</li>
                      <li>Flask</li>
                      <li>Node.js</li>
                      <li>FastAPI</li>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="tools">
                  <Row>
                    <Col
                      className="tools1"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={6}
                    >
                      <li>Azure DevOps</li>
                      <li>PyTorch</li>
                      <li>Pandas</li>
                      <li>Beautiful Soup 4</li>
                      <li>MySQL</li>
                      <li>Jenkins</li>
                    </Col>
                    <Col
                      className="tools2"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={6}
                    >
                      <li>Ansible</li>
                      <li>Scikit-learn</li>
                      <li>OpenCV</li>
                      <li>Dash/Plotly</li>
                      <li>JUnit</li>
                      <li>Microsoft Azure</li>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="business">
                  <Row>
                    <Col
                      className="business1"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={6}
                    >
                      <li>Microsft Excel</li>
                      <li>PowerBI</li>
                      <li>SQL</li>
                      <li>Enterprise Design Thinking (IBM Certified)</li>
                    </Col>
                    <Col
                      className="business2"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={6}
                    >
                      <li>SWOT Analysis</li>
                      <li>Trend Reporting</li>
                      <li>Product Management</li>
                      <li>Google Analytics</li>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <br />
        <br />
      </div>
    </Container>
  );
}

export default Skills;
