import "./Projects.css";
import React from "react";
import { Container, Row, Col } from "react-bootstrap/";
import AccuVision from "../images/AccuVision_Dash2.png";
import BlockPortal from "../images/BlockPortal2.png";
import Capstone from "../images/Capstone.png";
import Architecture from "../images/arch2.jpg";
import { useInView } from "react-intersection-observer";

function Projects() {
  const { ref: projectsRef, inView: projectsVisible } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <Container
      fluid
      className="projectsContainer"
      id="projects"
      ref={projectsRef}
    >
      <div
        className={`${"projectsOrig"} ${projectsVisible ? "projectsAnim" : "projectsOrig"
          }`}
      >
        <h1 className="projectTitle">Featured Projects</h1>
        <hr className="titleHr" />
        <br />
        <p className="text-start text-center">
          The following are some of my technical projects, more can be found on
          my{" "}
          <a
            href="https://github.com/DG-20"
            className="IEEElink"
            target="_blank"
            rel="noreferrer"
          >
            GitHub
          </a>
          .
        </p>
        <br />
        <div className="RBCCapstoneContainer container">
          <Row className="d-flex align-items-center justify-content-center">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="order-lg-first order-last">
              <a
                href="https://schulich.ucalgary.ca/sites/default/files/teams/4/2024%20Capstone/2024%20Capstone%20Projects/61925-49%20RealTime%20Weather%20Event%20Monitoring%20System.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <div className="CapstoneImgContainer">
                  <img src={Capstone} alt="Capstone Dashboard" />
                  <img src={Capstone} alt="Capstone Dashboard" />
                  <img src={Capstone} alt="Capstone Dashboard" />
                  <img src={Capstone} alt="Capstone Dashboard" />
                </div>
              </a>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <h1 className="CapstoneTitle">Real-Time Weather Event Monitoring System</h1>
              <Row>
                <p className="text-start">
                  A prototype created for RBC as our final-year capstone project. The comprehensive software suite
                  aggregates, processes, and visualizes real-time weather event data, designed to facilitate
                  swift and informed decision-making for RBC on a national scale.
                  <br />
                  Achieved processing speeds of under 10 minutes to process the impact of thousands of climate
                  events on millions of locations of interest in real-time and on-demand.
                </p>
              </Row>
              <Row>
                <Col
                  className="CapstoneTech1"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <li>Python</li>
                  <li>Fast API</li>
                  <li>Docker</li>
                </Col>
                <Col
                  className="CapstoneTech2"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <li>MongoDB</li>
                  <li>Next.js</li>
                  <li>Redis</li>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <div className="BlockPortalContainer container">
          <Row className="d-flex align-items-center justify-content-center">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <h1 className="BlockPortalTitle">BlockPortal</h1>
              <Row>
                <p className="text-start">
                  A prototype student portal service that integrates blockchain
                  technology with a seamless student experience. It allows for
                  secure, decentralized transactions which can be tracked and
                  easily viewed within BlockPortal.
                  <br />
                  This solution earned "Best Blockchain Project Using Hedera" at
                  the MakeUC 2022 Hackathon.
                </p>
              </Row>
              <Row>
                <Col
                  className="BlockPortalTech1"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <li>Django</li>
                  <li>Hedera Blockchain SDK</li>
                  <li>MongoDB</li>
                </Col>
                <Col
                  className="BlockPortalTech2"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <li>HTML, CSS, &amp; JS</li>
                  <li>PyMongo</li>
                  <li>Python</li>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <a
                href="https://github.com/DG-20/MakeUC"
                target="_blank"
                rel="noreferrer"
              >
                <div className="blockPortalImgContainer">
                  <img src={BlockPortal} alt="BlockPortal Dashboard" />
                  <img src={BlockPortal} alt="BlockPortal Dashboard" />
                  <img src={BlockPortal} alt="BlockPortal Dashboard" />
                  <img src={BlockPortal} alt="BlockPortal Dashboard" />
                </div>
              </a>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <div className="AccuVisionContainer container">
          <Row className="d-flex align-items-center justify-content-center">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={6}
              className="order-lg-first order-last"
            >
              <a
                href="https://github.com/DG-20/HackTogether---AccuVision"
                target="_blank"
                rel="noreferrer"
                className="AccuVisionA"
              >
                <div className="AccuVisionImgContainer">
                  <img src={AccuVision} alt="AccuVision Dashboard" />
                  <img src={AccuVision} alt="AccuVision Dashboard" />
                  <img src={AccuVision} alt="AccuVision Dashboard" />
                  <img src={AccuVision} alt="AccuVision Dashboard" />
                </div>
              </a>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <h1 className="AccuVisionTitle">AccuVision</h1>
              <Row>
                <p className="text-start">
                  A program which uses computer vision and uploads live
                  statistics of the number of people in an establishment onto a
                  user-friendly dashboard available to the public. Designed to
                  limit the spread of the Covid-19 pandemic.
                  <br />
                  This prototype solution earned first place at the HackTogether
                  2021 Hackathon.
                </p>
              </Row>
              <Row>
                {/* <ul className="AccuVisionTech"> */}
                <Col
                  className="AccuVisionTech1"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <li>Python</li>
                  <li>OpenCV</li>
                  <li>Pandas</li>
                </Col>
                <Col
                  className="AccuVisionTech2"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <li>Dash &amp; Plotly</li>
                  <li>HTML &amp; CSS</li>
                  <li>Google Drive API</li>
                </Col>
                {/* </ul> */}
              </Row>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <div className="researchContainer container">
          <Row className="d-flex align-items-center justify-content-center">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <h1 className="ResearchTitle">
                Person Re-Idenfication Deep Learning Model
              </h1>
              <Row>
                <p className="text-start">
                  A practical deep-learning model intended to perform person
                  re-identification through attribute recognition (soft
                  biometrics). This model proposes a shift in ongoing research
                  surrounding this area towards creating more explainable and
                  transparent models whilst maintaining accuracy and
                  performance.
                  <br />
                  The model is featured in our scientific paper,{" "}
                  <a
                    href="https://ieeexplore.ieee.org/document/9660071"
                    className="IEEElink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i>Towards Explainable Person Re-Identification</i>
                  </a>
                </p>
              </Row>
              <Row>
                {/* <ul className="BlockPortalTech"> */}
                <Col
                  className="ResearchTech1"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <li>PyTorch</li>
                  <li>Pandas</li>
                  <li>Scikit-Learn</li>
                </Col>
                <Col
                  className="ResearchTech2"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <li>Pandas</li>
                  <li>Numpy</li>
                  <li>PIL</li>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <a
                href="https://github.com/th-truong/explainable-id-reid"
                target="_blank"
                rel="noreferrer"
              >
                <div className="ArchitectureImgContainer">
                  <img src={Architecture} alt="Architecture Model" />
                  <img src={Architecture} alt="Architecture Model" />
                  <img src={Architecture} alt="Architecture Model" />
                  <img src={Architecture} alt="Architecture Model" />
                </div>
              </a>
            </Col>
          </Row>
        </div>
        <br />
        <br />
      </div>
    </Container>
  );
}

export default Projects;
