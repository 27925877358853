import "./AboutMe.css";
import { Container, Row, Col } from "react-bootstrap/";
import profile from "../images/profile_darkb.svg";
import { useInView } from "react-intersection-observer";

function AboutMe() {
  const { ref: aboutRef, inView: aboutVisible } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });
  return (
    <div>
      <Container fluid className="aboutMeContainer" id="aboutMe" ref={aboutRef}>
        <div>
          <h1 className="aboutMeTitle">About Me</h1>
          <hr className="titleHr" />
          <br />
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={6}
              className={`${"aboutMeTextOrig"} ${aboutVisible ? "aboutMeTextAnim" : "aboutMeTextOrig"
                }`}
            >
              <p className="text-start">I'm Div,</p>
              <p className="text-start">
                A fifth-year Combined Software Engineering and Business major
                at the University of Calgary. I have honed both my programming
                and professional abilities through various personal, academic,
                and professional endeavors. With a passion for solving
                real-world problems, I am always looking to grow my skills and
                expand my knowledge horizon.
              </p>
              <p className="text-start">
                I really enjoy playing basketball and tennis with my friends and exploring
                the Rockies through a pleasant hike. I have a keen interest in
                nature photography as I love to capture the various landscapes I
                encounter through my travel adventures. Keep scrolling to see
                some of my best shots!
              </p>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={6}
              style={{ zIndex: 100 }}
              className={`${"d-flex align-items-center justify-content-center aboutMeImgOrig"} ${aboutVisible ? "aboutMeImgAnim" : "aboutMeImgOrig"
                }`}
            >
              <img className="profileImg" src={profile} alt="Generic Profile" />
            </Col>
          </Row>
          <br />
          <br />
        </div>
      </Container>
    </div>
  );
}

export default AboutMe;
