import React, { useState } from "react";
import { Container } from "react-bootstrap/";
import "./WorkExperience.css";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";

function WorkExperience() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 896);
  const [BiometricClicked, setBiometricClicked] = useState(false);
  const [IBMClicked, setIBMClicked] = useState(false);
  const [BMOClicked, setBMOClicked] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 896);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });


  return (
    <Container
      fluid
      className="workExperienceContainer"
      id="technicalExperience"
    // ref={experienceRef}      
    >
      <div
        // className={`${"experienceOrig"} ${experienceVisible ? "experienceAnim" : "experienceOrig"
        //   }`}
        className="experienceVisible"
      >
        <h1 className="experienceTitle">Technical Experience</h1>
        <hr className="titleHr" />
        <br />
        <VerticalTimeline
          lineColor="#fff">
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(17, 18, 23)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #0561fc' }}
            date="May, 2024 - August, 2024 (4 Months)"
            dateClassName="dateElem"
            iconStyle={{ background: '#0561fc' }}
          >
            <h3 className="vertical-timeline-element-title">Technology Analyst Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">BMO</h4>
            <br></br>
            {!isMobile ?
              <><hr className="exptitleHr"></hr>
                <ul>
                  <ul className="text-start" style={{ fontSize: "2.5vh" }}>
                    <li style={{ paddingBottom: "10px" }}>
                      Leveraged Power BI to develop an executive-level dashboard showcasing DORA metrics, KPIs,
                      workload distribution, and development progress across multiple products. Automated real-time
                      data retrieval and refreshes, empowering data-driven decision-making and strategic planning.
                    </li>
                    <li>
                      Implemented dynamic and selective unit testing, based on development source changes,
                      within non-production and production pipelines.
                      Reduced deployment time by over 50% and reduced UAT and production defects by 30%.
                    </li>
                  </ul>
                </ul>
              </> : <><button
                className="IBMButton"
                onClick={() => setBMOClicked(!BMOClicked)}
                aria-controls="bachCommerceExpanded"
                aria-expanded={BMOClicked}
              >
                <b>
                  View Achievements
                  <i className="arrow down"></i>
                </b>
              </button>
                <Collapse in={BMOClicked}>
                  <div id="bachCommerceExpanded">
                    <br />
                    <hr className="exptitleHr"></hr>
                    <ul>
                      <ul className="text-start" style={{ fontSize: "2.5vh" }}>
                        <li style={{ paddingBottom: "10px" }}>
                          Leveraged Power BI to develop an executive-level dashboard showcasing DORA metrics, KPIs,
                          workload distribution, and development progress across multiple products. Automated real-time
                          data retrieval and refreshes, empowering data-driven decision-making and strategic planning.
                        </li>
                        <li>
                          Implemented dynamic and selective unit testing, based on development source changes,
                          within non-production and production pipelines.
                          Reduced deployment time by over 50% and reduced UAT and production defects by 30%.
                        </li>
                      </ul>
                    </ul>
                  </div>
                </Collapse></>}
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(17, 18, 23)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #0561fc' }}
            date="May, 2022 - August, 2023 (16 Months)"
            dateClassName="dateElem"
            iconStyle={{ background: '#0561fc' }}
          >
            <h3 className="vertical-timeline-element-title">DevOps Developer Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">IBM</h4>
            <br></br>
            {!isMobile ?
              <>
                <hr className="exptitleHr"></hr>
                <ul>
                  <ul className="text-start" style={{ fontSize: "2.5vh" }}>
                    <li style={{ paddingBottom: "10px" }}>
                      Designed and deployed automated solutions to
                      optimize the development of IBM Cloud Pak
                      documentation using Python, Batch, and Shell
                      scripts running on Jenkins servers.
                    </li>
                    <li>
                      Engineered versatile scripts to streamline mass
                      updates, file creation, and data consolidation of
                      thousands of XML-based DITA source files,
                      conserving over 400 hours of manual work.
                    </li>
                  </ul>
                </ul></> : <><button
                  className="IBMButton"
                  onClick={() => setIBMClicked(!IBMClicked)}
                  aria-controls="bachCommerceExpanded"
                  aria-expanded={IBMClicked}
                >
                  <b>
                    View Achievements
                    <i className="arrow down"></i>
                  </b>
                </button>
                <Collapse in={IBMClicked}>
                  <div id="bachCommerceExpanded">
                    <br />
                    <hr className="exptitleHr"></hr>
                    <ul>
                      <ul className="text-start" style={{ fontSize: "2.5vh" }}>
                        <li style={{ paddingBottom: "10px" }}>
                          Designed and deployed automated solutions to
                          optimize the development of IBM Cloud Pak
                          documentation using Python, Batch, and Shell
                          scripts running on Jenkins servers.
                        </li>
                        <li>
                          Engineered versatile scripts to streamline mass
                          updates, file creation, and data consolidation of
                          thousands of XML-based DITA source files,
                          conserving over 400 hours of manual work.
                        </li>
                      </ul>
                    </ul>
                  </div>
                </Collapse></>}
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(17, 18, 23)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #0561fc' }}
            date="May, 2021 - August, 2021 (4 months)"
            dateClassName="dateElem"
            iconStyle={{ background: '#0561fc', color: '#fff' }}
          >
            <h3 className="vertical-timeline-element-title">Undergraduate Student Researcher</h3>
            <h4 className="vertical-timeline-element-subtitle">Biometric Technologies Laboratory</h4>
            <br></br>
            {!isMobile ?
              <>
                <hr className="exptitleHr"></hr>
                <ul>
                  <ul className="text-start" style={{ fontSize: "2.5vh" }}>
                    <li style={{ paddingBottom: "10px" }}>
                      Designed a robust deep learning model using
                      PyTorch (Python) to perform person
                      re-identification based on attribute
                      classification.
                    </li>
                    <li>
                      First author of the{" "}
                      <strong>
                        <a
                          href="https://ieeexplore.ieee.org/document/9660071"
                          className="IEEElink"
                          target="_blank"
                          rel="noreferrer"
                        >
                          scientific publication in the IEEE Symposium
                          Series on Computational Intelligence (SSCI)
                          2021
                        </a>
                      </strong>
                      , outlining our proposition to shift the focus of
                      research towards developing more applicable
                      models.
                    </li>
                  </ul>
                </ul></> : <><button
                  className="IBMButton"
                  onClick={() => setBiometricClicked(!BiometricClicked)}
                  aria-controls="bachCommerceExpanded"
                  aria-expanded={BiometricClicked}
                >
                  <b>
                    View Achievements
                    <i className="arrow down"></i>
                  </b>
                </button>
                <Collapse in={BiometricClicked}>
                  <div id="bachCommerceExpanded">
                    <br />
                    <hr className="exptitleHr"></hr>
                    <ul>
                      <ul className="text-start" style={{ fontSize: "2.5vh" }}>
                        <li style={{ paddingBottom: "10px" }}>
                          Designed a robust deep learning model using
                          PyTorch (Python) to perform person
                          re-identification based on attribute
                          classification.
                        </li>
                        <li>
                          First author of the{" "}
                          <strong>
                            <a
                              href="https://ieeexplore.ieee.org/document/9660071"
                              className="IEEElink"
                              target="_blank"
                              rel="noreferrer"
                            >
                              scientific publication in the IEEE Symposium
                              Series on Computational Intelligence (SSCI)
                              2021
                            </a>
                          </strong>
                          , outlining our proposition to shift the focus of
                          research towards developing more applicable
                          models.
                        </li>
                      </ul>
                    </ul>
                  </div>
                </Collapse></>}
          </VerticalTimelineElement>
        </VerticalTimeline>
        <br />
        <br />
      </div>
    </Container>
  );
}

export default WorkExperience;
