import "./App.css";
import NavbarComponent from "./Components/NavbarComponent";
import LandingPage from "./Components/LandingPage";
import AboutMe from "./Components/AboutMe";
import Education from "./Components/Education";
import Skills from "./Components/Skills";
import Projects from "./Components/Projects";
import PhotographyPortfolio from "./Components/PhotographyPortfolio";
import Footer from "./Components/Footer";
import TechnicalExperience2 from "./Components/WorkExperience2";

function App() {
  return (
    <div className="App" id="dark">
      <NavbarComponent />
      <LandingPage />
      <AboutMe />
      <Education />
      <Skills />
      <TechnicalExperience2 />
      <Projects />
      <PhotographyPortfolio />
      <Footer />
    </div>
  );
}

export default App;
