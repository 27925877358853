import { Navbar, Nav } from "react-bootstrap/";
import "./NavbarComponent.css";

function NavbarComponent() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="navbarMainTag fixed-top fs-4"
      style={{
        paddingRight: "2%",
        paddingTop: "0%",
        height: "10vh !important",
        backgroundColor: "#1b1c21",
      }}
    >
      <Navbar.Brand className="dgLogoContainer" href="#">
        DG
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="navbarToggler"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="white"
          className="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </Navbar.Toggle>
      <Navbar.Collapse
        id="responsive-navbar-nav"
        style={{ paddingright: "3%" }}
      >
        <Nav className="m-auto fs-5 NavContent">
          <Nav.Link
            className="underlineNav"
            href="#aboutMe"
            style={{ color: "white" }}
          >
            About Me
          </Nav.Link>
          <Nav.Link
            className="underlineNav"
            href="#education"
            style={{ color: "white" }}
          >
            Education
          </Nav.Link>
          <Nav.Link
            className="underlineNav"
            href="#skills"
            style={{ color: "white" }}
          >
            Skills
          </Nav.Link>
          <Nav.Link
            className="underlineNav"
            href="#technicalExperience"
            style={{ color: "white" }}
          >
            Work Experiences
          </Nav.Link>
          <Nav.Link
            className="underlineNav"
            href="#projects"
            style={{ color: "white" }}
          >
            Projects
          </Nav.Link>
          <Nav.Link
            className="underlineNav"
            href="#photographyPortfolio"
            style={{ color: "white" }}
          >
            Photography Portfolio
          </Nav.Link>
        </Nav>
        <Nav className="d-flex center-me align-items-center justify-content-center">
          <a href="#contactMe" className="resumeNavButton">
            Contact Me
          </a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarComponent;
