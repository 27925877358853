import React from "react";
import { Container, Row, Col } from "react-bootstrap/";
import "./Education.css";
import { useState } from "react";
import education from "../images/education_darkb.svg";
import Collapse from "react-bootstrap/Collapse";
import { useInView } from "react-intersection-observer";

function Education() {
  const [bachScienceClicked, setbachScienceClicked] = useState(false);
  const [bachCommerceClicked, setbachCommerceClicked] = useState(false);

  const { ref: educationRef, inView: educationVisible } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  return (
    <Container
      fluid
      className="educationContainer"
      id="education"
      ref={educationRef}
    >
      <div
        className={`${"educationOrig"} ${educationVisible ? "educationAnim" : "educationOrig"
          }`}
      >
        <h1 className="educationTitle">Education</h1>
        <hr className="titleHr" />
        <br />
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            xxl={6}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              className="educationImg"
              src={education}
              alt="Education Generic"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            xxl={6}
            className="d-flex align-items-center buttons"
          >
            <div className="buttonsEducation">
              <div className="scienceSection">
                <button
                  className="bachScienceButton"
                  onClick={() => setbachScienceClicked(!bachScienceClicked)}
                  aria-controls="bachScienceExpanded"
                  aria-expanded={bachScienceClicked}
                >
                  <b>Bachelor of Science in Software Engineering</b>
                </button>
                <Collapse in={bachScienceClicked}>
                  <div id="bachScienceExpanded">
                    <br />
                    <ul className="text-start educationList">
                      <li>
                        University of Calgary (4<sup>th</sup> year)
                      </li>
                      <li>
                        <b>Scholarships and Achievements:</b>
                        <ul>
                          <li>
                            Woitas Family Leaders Scholarship, 2024</li>
                          <li>WESCO Student Achievement Award, 2022</li>
                          <li>
                            Schulich School of Engineering Dean's List,
                            2019-2022
                          </li>
                          <li>Jason Lang Scholarship recipient, 2020-2022</li>
                          <li>President's Admission Scholarship in 2019</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
              <div className="commerceSection">
                <button
                  className="bachCommerceButton"
                  onClick={() => setbachCommerceClicked(!bachCommerceClicked)}
                  aria-controls="bachCommerceExpanded"
                  aria-expanded={bachCommerceClicked}
                >
                  <b>
                    Bachelor of Commerce in Business{" "}
                    <i className="arrow down"></i>
                  </b>
                </button>
                <Collapse in={bachCommerceClicked}>
                  <div id="bachCommerceExpanded">
                    <br />
                    <ul className="text-start educationList">
                      <li>
                        University of Calgary (4<sup>th</sup> year)
                      </li>
                      <li>
                        <b> Scholarships and Achievements:</b>
                        <ul>
                          <li>
                            HSBC Bank Canada International Business Award, 2024</li>
                          <li>
                            Haskayne School of Business Dean's List, 2019-2024
                          </li>
                          <li>Michael W. Johnson Memorial Scholarship, 2022</li>
                          <li>Distinguished Business Leader Award, 2021</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <br />
      </div>
    </Container>
  );
}

export default Education;
