import React from "react";
import { Container, Row, Col } from "react-bootstrap/";
import Linkedin from "../images/linkedin2.svg";
import GitHub from "../images/github.svg";
import Email from "../images/email.svg";
import "./Footer.css";

function Footer() {
  return (
    <Container fluid className="contactContainer" id="contactMe">
      <hr className="hrFooter" />
      <Row>
        <Container className="contactContainerIcons">
          <Row>
            <h1 className="footerTitle">Feel Free to Reach Out</h1>
          </Row>
          <Row>
            <p className="text-start text-center">
              I am always looking to expand my knowledge through meeting new
              people, so feel free to message me on any of my social medias!
            </p>
          </Row>
          <Row className="iconsRow text-center">
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <div>
                <a
                  href="https://www.linkedin.com/in/divyansh-goyal2001/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    src={Linkedin}
                    alt="LinkedIn Logo"
                    className="LinkedInicon"
                  />
                </a>
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <div>
                <a
                  href="https://github.com/DG-20"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={GitHub} alt="GitHub Logo" className="githubicon" />
                </a>
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <div>
                <a
                  href="mailto:divyansh.goyal2001@gmail.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    src={Email}
                    alt="Email Logo"
                    className="emailicon"
                    datatoggle="tooltip"
                    dataplacement="left"
                    title="Tooltip on left"
                  />
                </a>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <p className="text-center">
              Designed &amp; developed by Div Goyal :)
            </p>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

export default Footer;
