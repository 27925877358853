import "./Projects.css";
import React from "react";
import { Container, Row, Carousel } from "react-bootstrap/";
import "./PhotographyPortfolio.css";
import Colorful from "../images/Photo_gallery/Colorful.jpg";
import Storm from "../images/Photo_gallery/Calm Before the Storm.jpg";
import Flare from "../images/Photo_gallery/Flare.jpg";
import Sunny_trail from "../images/Photo_gallery/sunny_trail.jpg";
import Waterfall from "../images/Photo_gallery/waterfall2.JPG";
import Beach from "../images/Photo_gallery/Beach2.jpg";
import Serenity from "../images/Photo_gallery/Ocean2.jpg";
import { useInView } from "react-intersection-observer";
import Hike from "../images/Photo_gallery/Hike.jpg";
import Tofino from "../images/Tofino.png"

function PhotographyPortfolio() {
  const { ref: photographyRef, inView: photographyVisible } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });
  return (
    <Container
      fluid
      className="photographyPortfolioContainer"
      id="photographyPortfolio"
      ref={photographyRef}
    >
      <div
        className={`${"photographyOrig"} ${photographyVisible ? "photographyAnim" : "photographyOrig"
          }`}
      >
        <h1 className="photographyTitle">Photography Portfolio</h1>
        <hr className="titleHr" />
        <br />
        <p className="text-start text-center">
          Here are some of my favorite photos that I have taken!
        </p>
        <div className="photographyPortfolioContainer container">
          <Row className="d-flex align-items-center justify-content-center CarouselRow">
            <Carousel className="Carousel" pause="hover" indicators={false}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Flare}
                  alt="Flare"
                  style={{
                    borderRadius: "5px",
                    boxShadow: "5px 5px 10px black",
                  }}
                />
                <br />
                <Carousel.Caption className="CarouselCaption">
                  <p className="text-start text-center">Flare</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Beach}
                  alt="Bliss"
                  style={{
                    borderRadius: "5px",
                    boxShadow: "5px 5px 10px black",
                  }}
                />
                <br />
                <Carousel.Caption className="CarouselCaption">
                  <p className="text-start text-center">Bliss</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Serenity}
                  alt="Serenity"
                  style={{
                    borderRadius: "5px",
                    boxShadow: "5px 5px 10px black",
                  }}
                />
                <br />
                <Carousel.Caption className="CarouselCaption">
                  <p className="text-start text-center">Serenity</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Storm}
                  alt="Storm"
                  style={{
                    borderRadius: "5px",
                    boxShadow: "5px 5px 10px black",
                  }}
                />
                <br />
                <Carousel.Caption className="CarouselCaption">
                  <p className="text-start text-center">
                    Calm Before the Storm
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Sunny_trail}
                  alt="Sunny Trail"
                  style={{
                    borderRadius: "5px",
                    boxShadow: "5px 5px 10px black",
                  }}
                />
                <br />
                <Carousel.Caption className="CarouselCaption">
                  <p className="text-start text-center">Sunny Trail</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 falls"
                  src={Waterfall}
                  alt="Waterfall"
                  style={{
                    borderRadius: "5px",
                    boxShadow: "5px 5px 10px black",
                  }}
                />
                <br />
                <Carousel.Caption className="CarouselCaption">
                  <p className="text-start text-center">Smooth Falls</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Colorful}
                  alt="Colorful"
                  style={{
                    borderRadius: "5px",
                    boxShadow: "5px 5px 10px black",
                  }}
                />
                <br />
                <Carousel.Caption className="CarouselCaption">
                  <p className="text-start text-center">Colorful</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Hike}
                  alt="Hike"
                  style={{
                    borderRadius: "5px",
                    boxShadow: "5px 5px 10px black",
                  }}
                />
                <br />
                <Carousel.Caption className="CarouselCaption">
                  <p className="text-start text-center">Hiking</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={Tofino}
                  alt="Tofino"
                  style={{
                    borderRadius: "5px",
                    boxShadow: "5px 5px 10px black",
                  }}
                />
                <br />
                <Carousel.Caption className="CarouselCaption">
                  <p className="text-start text-center">Edge of the World</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default PhotographyPortfolio;
